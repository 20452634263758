<template>
  <div class="resource payment-eximbay">
    <div class="container">
      <ui-form ref="form" @submit="submitForm" :formData="formDataOnActiveTab">
        <ui-form-list>
          <!--카드번호-->
          <ui-form-list-item>
            <ui-text-input
              ref="input-card"
              :value="formData.card.value"
              :error="formData.card.error"
              :placeholder="$__t('카드번호를 입력해주세요.')"
              :type="formData.card.type"
              :label="$__t('Card Number')"
              :inputmode="formData.card.inputmode"
              :mask="formData.card.mask"
              @input="
                formData.card.value = $event;
                onInput(formData.card, 'input-exp-date');
              "
            />
          </ui-form-list-item>

          <ui-form-list-multiple-item>
            <!--유효기간-->
            <ui-text-input
              ref="input-exp-date"
              :value="formData.expDate.value"
              :error="formData.expDate.error"
              size="medium"
              :placeholder="'MM / YY'"
              :type="formData.expDate.type"
              :label="$__t('Expiration Date')"
              :inputmode="formData.expDate.inputmode"
              :mask="formData.expDate.mask"
              @input="
                formData.expDate.value = $event;
                onInput(formData.expDate, 'input-cvc');
              "
            />
            <!--CVC-->
            <ui-text-input
              ref="input-cvc"
              :value="formData.cvc.value"
              :error="formData.cvc.error"
              :placeholder="$__t('숫자 3자리')"
              :maxlength="3"
              :label="'CVC'"
              :inputmode="formData.cvc.inputmode"
              :type="formData.cvc.type"
              :mask="formData.cvc.mask"
              @input="
                formData.cvc.value = $event;
                onInput(formData.cvc, 'input-card-pass');
              "
            />
          </ui-form-list-multiple-item>
          <ui-form-list-multiple-item>
            <!--이메일-->
            <ui-text-input
              ref="input-email"
              v-model="formData.email.value"
              :error="formData.email.error"
              :placeholder="'user@domain.com'"
              :type="formData.email.type"
              :label="$__t('Email')"
              :inputmode="formData.email.inputmode"
              :mask="formData.email.mask"
            />
          </ui-form-list-multiple-item>
        </ui-form-list>
        <div class="label-text-area">
          <div class="label-text-area__label">
            <img src="/img/icon/l-information.svg" />
          </div>
          <div class="label-text-area__text">
            {{ $__t('결제 수단 등록/변경 시 인증을 위해 100원 결제 후 즉시 자동 환불됩니다.') }}
          </div>
        </div>
        <div class="submit-area">
          <button type="submit" v-button :class="{ activated: activated }" :disabled="!activated">
            {{ $__t('등록하기') }}
          </button>
        </div>
      </ui-form>
    </div>
  </div>
</template>

<script>
import UiTextInput from '@/v3/components/common/form/TextInput.vue';

// 비인증
// VISA : 4111-1111-1111-1111 / 12월 30년 / CVC : 123
// MASTER : 5310-1111-1111-1111 / 12월 30년 / CVC : 123
// JCB : 3540-9311-1111-1111 / 12월 30년 / CVC : 123
// AMEX : 3772-2222-2211-009 / 12월 33년 / CVC : 123
// Union Pay : 6250947000000014 / 12월 33년 / CVC : 123 / 메세지 인증 번호 PC : 111111 / Mobile : 123455

// 인증
// VISA : 4000-0000-0000-0002 / 12월 33년 / CVC : 123
// MASTER : 5200-0000-0000-0007 / 12월 33년 / CVC : 123
// JCB : 3569-9900-1008-3722 / 12월 33년 / CVC : 123

export default {
  components: {
    UiTextInput
  },
  props: ['redirect'],
  data() {
    return {
      formData: {
        card: {
          value: '',
          validation: [{ type: 'required' }],
          label: '',
          error: '',
          inputmode: 'numeric',
          type: 'tel',
          mask: '####-####-####-####',
          maxlength: 16
        },
        expDate: {
          value: '',
          validation: [{ type: 'required' }],
          label: '',
          error: '',
          inputmode: 'numeric',
          type: 'tel',
          mask: '##/##',
          maxlength: 4
        },
        cvc: {
          value: '',
          validation: [{ type: 'required' }],
          label: '',
          error: '',
          inputmode: 'numeric',
          type: 'password',
          mask: '###',
          maxlength: 3
        },
        email: {
          value: '',
          validation: [{ type: 'required' }],
          label: '',
          error: '',
          type: 'email'
        }
      }
    };
  },

  computed: {
    activated() {
      return Object.keys(this.formDataOnActiveTab).every((o) => {
        return !!this.formDataOnActiveTab[o].value;
      });
    },

    formDataOnActiveTab() {
      return this.formData;
    }
  },

  methods: {
    submitForm(values) {
      this.$store.commit('loading/SET_TRUE');
      let { card, cvc, expDate, email } = values;

      return this.$store
        .dispatch('billings/addCard', {
          billing_gateway: 'EXIMBAY',
          card_number: card,
          card_cvc: cvc,
          expiration_year: expDate.substr(2, 2),
          expiration_month: expDate.substr(0, 2),
          email: email,
          is_primary: '1'
        })
        .then((res) => {
          // console.log('res', res);
          if (res.data.data.status === 'AUTHORIZED') {
            this.$store.commit('notice/ADD_ITEM', {
              message: this.$__t('등록되었습니다 .')
            });
            this.$analytics.logEvent('add payment info');
            if (this.$props.redirect.redirect) {
              return this.$router.push({
                name: this.$props.redirect.redirect,
                query: {
                  back: 'Home'
                }
              });
            }
            return this.$router.go(-2);
          } else {
            this.$analytics.logEvent('error payment info');
            return this.$store.commit('alert/ADD_ITEM', {
              message: this.$__t('Invalid card information'),
              status: 'error'
            });
          }
        })
        .catch((error) => {
          this.$analytics.logEvent('error payment info');
          if (error?.data?.error === '이미 등록된 카드 정보입니다.') {
            return this.$store.commit('alert/ADD_ITEM', {
              message: this.$__t(error.data.error),
              status: 'error'
            });
          }

          this.$store.commit('alert/ADD_ITEM', {
            message: this.$__t('Invalid card information'),
            status: 'error'
          });
          this.$analytics.logEvent('error payment info', { error: error });
        })
        .finally(() => {
          setTimeout(() => this.$store.commit('loading/SET_FALSE'), 500);
        });
    },

    onInput(form_property, focus_ref) {
      let { value, maxlength } = form_property;

      if (String(value).length === maxlength) {
        const input = this.$refs[focus_ref];
        if (input) {
          setTimeout(() => {
            input.focus();
          }, 0);
        }
      }
    }
  }
};
</script>

<style lang="scss">
.resource.payment-eximbay {
  form {
    margin-top: unit(16);

    .ui-form-list-item {
      display: flex;
      gap: unit(18);

      & + .ui-form-list-item {
        margin-top: unit(25);
      }

      & > * {
        flex: 1;
      }
    }

    .ui-form-list-multiple-item::after {
      display: none;
    }

    .label-text-area {
      margin-top: unit(14);

      &__text {
        padding-right: unit(6);
      }
    }
  }

  .submit-area {
    margin-top: unit(28);
    padding: unit(16) 0;

    button {
      color: $color-white;
      background-color: #a9afb3;
      border-radius: unit(6);
      line-height: 1.5;
      text-transform: uppercase;
      cursor: not-allowed;
      padding: unit(14) unit(28);
      font-weight: 500;
      font-size: unit(15);

      &.activated {
        background-color: #0099ff;
        cursor: pointer;
      }
    }
  }
}
</style>
